import React, { useEffect } from 'react'
import 'twin.macro'

import tw from 'twin.macro'

import SEO from 'components/shared/seo'
import useStudios from 'content-queries/mx/new/studios'
import fireEvent from 'context/tracking/events'
import { AutomaticPopup } from 'mx/automatic-popup'
import { MainContent } from 'mx/components/new'
import Layout from 'mx/layout'

const StudiosPage = ({ path }: { path: string }) => {
  useEffect(() => {
    fireEvent({
      type: 'page_viewed',
      pageTitle: 'Studios',
    })
  }, [])

  const { data } = useStudios()

  const [
    {
      node: {
        sectionModules: [hero, newYork, sanFrancisco, denver],
      },
    },
  ] = data

  return (
    <Layout path={path}>
      <AutomaticPopup />
      <MainContent header data={hero} />
      <MainContent data={newYork} inline classname={tw`mt-20 lg:mt-32`} />
      <MainContent data={sanFrancisco} inline classname={tw`mt-20 lg:mt-32`} />
      <MainContent data={denver} inline classname={tw`my-20 lg:my-32`} />
    </Layout>
  )
}

export default StudiosPage

export const Head = () => {
  const { data } = useStudios()
  const [
    {
      node: {
        image,
        meta: { title, description },
      },
    },
  ] = data
  return (
    <SEO
      description={description}
      path="/studios"
      title={title}
      image={
        image.localFile?.childImageSharp?.gatsbyImageData?.images?.fallback?.src
      }
    />
  )
}
